//project grid
import React from "react"
import { graphql, Link } from "gatsby"
import styled from 'styled-components'
import Layout from "../components/layout"
import SEO from "../components/seo"

const TitleLink = styled.h3`
  marginBottom: 20;
  &:hover{
    color: #58a89e;
  }
  a {
    color: inherit;
    boxShadow: none;
    text-decoration: none;
    &:visited{
      color: inherit;
    }
  }
`;

const BlogPage = ({data}) => {
  const posts = data.allMarkdownRemark.edges;
  return (
  <Layout>
    <SEO title="Blog" />
    <h2>Latest Posts</h2>
    {posts.map(({ node }) => {
          const title = node.frontmatter.title || node.fields.slug
          return (
            <article key={node.fields.slug}>
              <header>
              <small>{node.frontmatter.date}</small>
                <TitleLink>
                  <Link to={node.fields.slug}>

                    {title}
                  </Link>
                </TitleLink>

              </header>
              {/* <section>
                <p
                  dangerouslySetInnerHTML={{
                    __html: node.frontmatter.description || node.excerpt,
                  }}
                />
              </section> */}
            </article>
          )
        })}
  </Layout>
  );
}


export default BlogPage
export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        title
      }
    }
    allMarkdownRemark(
      filter: {frontmatter: {posttype: {eq: "blog"}}}
      sort: { fields: [frontmatter___date], order: DESC }) {
      edges {
        node {
          excerpt
          fields {
            slug
          }
          frontmatter {
            date(formatString: "MMMM DD, YYYY")
            title
          }
        }
      }
    }
  }
`